




import {Component, Vue} from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class App extends Vue {
  mounted() {
    document.dispatchEvent(new Event('custom-render-trigger'))
  }
}
