import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
// @ts-ignore
import MetaInfo from 'vue-meta-info'

import router from '@/router'
import App from './App.vue'
import './icons/svg'

Vue.config.productionTip = false
Vue.use(VueCompositionApi);
Vue.use(MetaInfo)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
